var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-modal",
        {
          staticClass: "send-task",
          attrs: {
            width: "38%",
            height: "auto",
            title: _vm.title,
            "show-zoom": true,
            resize: "",
            "lock-view": false,
            mask: true,
            position: _vm.position,
            transfer: "",
          },
          on: {
            close: _vm.handleCloseTaskModal,
            hide: _vm.handleCloseTaskModal,
          },
          model: {
            value: _vm.ModalVisible,
            callback: function ($$v) {
              _vm.ModalVisible = $$v
            },
            expression: "ModalVisible",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "a-form-model",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    layout: "horizontal",
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "返回固定点任务开关" } },
                    [
                      _c("a-switch", {
                        attrs: {
                          "checked-children": "开",
                          "un-checked-children": "关",
                        },
                        on: { change: _vm.handleOpenChange },
                        model: {
                          value: _vm.form.open,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "open", $$v)
                          },
                          expression: "form.open",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "使用的任务模板", prop: "taskName" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "show-search": "",
                            placeholder: "支持模糊搜索选择",
                            "default-active-first-option": false,
                            "filter-option": false,
                            "not-found-content": null,
                            loading: _vm.loading,
                            disabled: !_vm.form.open,
                          },
                          on: {
                            search: _vm.handleTaskSearch,
                            change: _vm.handleTaskChange,
                            focus: _vm.handleTaskSearch2,
                          },
                          model: {
                            value: _vm.form.vehicleTaskId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "vehicleTaskId", $$v)
                            },
                            expression: "form.vehicleTaskId",
                          },
                        },
                        _vm._l(_vm.taskArray, function (d) {
                          return _c("a-select-option", { key: d.id }, [
                            _vm._v(" " + _vm._s(d.taskName) + " "),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.taskName
                    ? [
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "任务类型", prop: "taskType" } },
                          [_c("span", [_vm._v(_vm._s(_vm.getTaskTypeName()))])]
                        ),
                        ["0", "2"].indexOf(_vm.form.taskType) >= 0
                          ? [
                              _c(
                                "a-form-model-item",
                                { attrs: { label: "地图ID", prop: "mapId" } },
                                [_c("span", [_vm._v(_vm._s(_vm.form.mapId))])]
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "地图版本",
                                    prop: "mapVersion",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.form.mapVersion)),
                                  ]),
                                ]
                              ),
                              _vm.form.taskType === "0"
                                ? _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: "循迹路径目录名称",
                                        prop: "routeFolderName",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.form.routeFolderName)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.form.lon
                                ? _c(
                                    "a-form-model-item",
                                    { attrs: { label: "经度" } },
                                    [_c("span", [_vm._v(_vm._s(_vm.form.lon))])]
                                  )
                                : _vm._e(),
                              _vm.form.lat
                                ? _c(
                                    "a-form-model-item",
                                    { attrs: { label: "纬度" } },
                                    [_c("span", [_vm._v(_vm._s(_vm.form.lat))])]
                                  )
                                : _vm._e(),
                            ]
                          : _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "选择路线",
                                  prop: "taskRouteId",
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(_vm.form.routeName))])]
                            ),
                      ]
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "返回固定点名称", prop: "remark" } },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入",
                          disabled: !_vm.form.open,
                        },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("dialog-bottom-bar", {
                attrs: { "primary-text": "确定", "second-text": "取消" },
                on: {
                  onConfirm: _vm.submitForm,
                  onCancel: _vm.handleCancelTask,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("GetLocationOnMap", {
        ref: "mapRef",
        on: { onLocation: _vm.handleGetLocation },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }