<template>
  <!-- 新增执行任务 -->
  <div>
    <vxe-modal
      v-model="ModalVisible"
      width="38%"
      height="auto"
      :title="title"
      :show-zoom="true"
      resize
      :lock-view="false"
      :mask="true"
      :position="position"
      transfer
      @close="handleCloseTaskModal"
      @hide="handleCloseTaskModal"
      class="send-task"
    >
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" layout="horizontal" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="返回固定点任务开关">
            <a-switch checked-children="开" un-checked-children="关" v-model="form.open" @change="handleOpenChange" />
          </a-form-model-item>
          <a-form-model-item label="使用的任务模板" prop="taskName">
            <a-select
              show-search
              placeholder="支持模糊搜索选择"
              :default-active-first-option="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleTaskSearch"
              @change="handleTaskChange"
              @focus="handleTaskSearch2"
              :loading="loading"
              v-model="form.vehicleTaskId"
              :disabled="!form.open"
            >
              <a-select-option v-for="d in taskArray" :key="d.id">
                {{ d.taskName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <template v-if="form.taskName">
            <a-form-model-item label="任务类型" prop="taskType">
              <span>{{ getTaskTypeName() }}</span>
            </a-form-model-item>
            <!-- 循迹任务 -->
            <template v-if="['0', '2'].indexOf(form.taskType) >= 0">
              <a-form-model-item label="地图ID" prop="mapId">
                <span>{{ form.mapId }}</span>
              </a-form-model-item>
              <a-form-model-item label="地图版本" prop="mapVersion">
                <span>{{ form.mapVersion }}</span>
              </a-form-model-item>
              <a-form-model-item label="循迹路径目录名称" prop="routeFolderName" v-if="form.taskType === '0'">
                <span>{{ form.routeFolderName }}</span>
              </a-form-model-item>
              <a-form-model-item label="经度" v-if="form.lon">
                <span>{{ form.lon }}</span>
              </a-form-model-item>
              <a-form-model-item label="纬度" v-if="form.lat">
                <span>{{ form.lat }}</span>
              </a-form-model-item>
            </template>
            <a-form-model-item v-else label="选择路线" prop="taskRouteId">
              <span>{{ form.routeName }}</span>
            </a-form-model-item>
          </template>
          <a-form-model-item label="返回固定点名称" prop="remark">
            <a-input v-model="form.remark" placeholder="请输入" :disabled="!form.open" />
          </a-form-model-item>
        </a-form-model>
        <dialog-bottom-bar primary-text="确定" second-text="取消" @onConfirm="submitForm" @onCancel="handleCancelTask" />
      </div>
    </vxe-modal>
    <GetLocationOnMap @onLocation="handleGetLocation" ref="mapRef" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { listVehicleTask } from '@/api/iot/vehicleTask'
import { autoReset, autoResetAck, getTaskAutoReset, cancelAutoReset } from '@/api/iot/taskScheduler'
import DialogBottomBar from '@/components/DialogBottomBar'
import TaskBase from './TaskBase.js'
import GetLocationOnMap from './GetLocationOnMap'
export default {
  mixins: [TaskBase],
  name: 'CreateExecTaskModal',
  components: { DialogBottomBar, GetLocationOnMap },
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    position: {
      type: Object,
      default: () => {}
    },
    isShowModal: {
      type: Boolean,
      default: () => {}
    },
    vehicleId: {
      type: String,
      default: () => {}
    },
    taskForm: {
      type: Object,
      default: null
    },
    taskTemplateForm: {
      type: Object,
      default: null
    },
    isTimedTask: {
      type: Boolean,
      default: () => {}
    }
  },
  data() {
    // 节流控制
    this.handleTaskSearch = debounce(this.handleTaskSearch, 500)
    return {
      ModalVisible: this.isShowModal,
      confirmDisabled: true,
      form: {
        id: null,
        vehicleId: null,
        taskName: null,
        startTime: null, // 开始日期时间
        expiryDateTime: null,
        taskRouteId: '',
        remark: null,
        taskConflict: null,
        taskType: '1',
        schedulerType: 4,
        lon: null,
        lat: null,
        open: false
      },
      rules: {
        taskName: [{ required: true, message: '模板名称不能为空', trigger: 'change' }],
        remark: [{ required: true, message: '返回固定点名称不能为空', trigger: 'change' }]
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 17 },
      routeArray: [],
      loading: false,
      title: this.vehicleId + '-返回固定点任务设置',
      vehicleTaskConflicts: [],
      isEdit: false,
      // 任务类型
      taskTypeOptions: [
        {
          dictLabel: 'LaneId任务',
          dictValue: '1'
        },
        {
          dictLabel: '循迹任务',
          dictValue: '0'
        },
        {
          dictLabel: '固定点任务',
          dictValue: '2'
        }
      ],
      timeOpen: false,
      taskArray: [],
      isExit: false
    }
  },
  created() {
    this.getDictData()
  },
  mounted() {
    if (this.taskForm) {
      this.initTaskForm(this.taskForm)
    }
    if (this.taskTemplateForm) {
      this.initTaskTemplateForm(this.taskTemplateForm)
    }
    console.log('info', this.info)
    const businessState = this.info ? this.info.businessState : {}
    if (businessState) {
      this.currentMapId = businessState.map_id
      this.currentMapVersion = businessState.map_version
    }
  },
  destroyed() {
    console.log('send task destroyed')
    this.clearTimeoutEx()
  },
  watch: {
    'info.vehicleId'(newVal, oldVal) {
      console.log('创建任务窗口关闭', newVal + '=' + oldVal)
      this.$emit('closeTaskModal')
      this.ModalVisible = false
    },
    taskForm(newVal, oldVal) {
      console.log('taskForm', newVal)
      this.initTaskForm(newVal)
    },
    taskTemplateForm(newVal, oldVal) {
      this.initTaskTemplateForm(newVal)
    }
  },
  methods: {
    handleOpenChange(checked) {
      if (checked) {
        if (!this.rules && this.rulesCopy) {
          this.rules = { ...this.rulesCopy }
        }
      } else {
        if (this.rules) {
          this.rulesCopy = { ...this.rules }
        }
        this.rules = null
      }
      this.$refs.form.clearValidate()
    },
    getDictData() {
      // 车端回传结果状态
      this.getDicts('iot_vehicle_remoteCtlResult').then((response) => {
        this.vehicleRemoteCtlResultOptions = response.data
      })
    },
    getTaskTypeName() {
      return this.selectDictLabel(this.taskTypeOptions, this.form.taskType)
    },
    clearFormFields() {
      this.form.id = null
      this.form.startTime = null
      this.form.createTime = null
      this.form.updateTime = null
      this.form.createBy = null
      this.form.expiryDateTime = null
      this.form.remark = null
      this.form.vehicleCreate = null
    },
    initTaskForm(form) {
      this.isEdit = true
      this.getCurrentResetTask()
    },
    // 获取当前返回固定点任务
    getCurrentResetTask() {
      const params = {
        vehicleId: this.vehicleId,
        mapId: this.currentMapId,
        mapVersion: this.currentMapVersion
      }
      getTaskAutoReset(params).then((response) => {
        console.log('getTaskAutoReset data', response)

        const form = response.data
        this.form.open = false
        if (form) {
          const taskState = form.taskState
          if (taskState !== '8') {
            this.form.open = true
          }
          this.form = { ...form }
          this.taskArray = [
            {
              id: form.vehicleTaskId,
              taskName: form.taskName
            }
          ]
        }
        this.handleOpenChange(this.form.open)
      })
    },
    initTaskTemplateForm(templateForm) {
      // 基于任务模板创建执行任务
      this.form.mapId = templateForm.mapId
      this.form.mapVersion = templateForm.mapVersion
      this.form.routeFolderName = templateForm.routeFolderName
      this.form.taskRouteId = templateForm.taskRouteId
      this.form.taskType = templateForm.taskType
      this.form.vehicleTaskId = templateForm.id
      this.taskArray = [
        {
          id: templateForm.id,
          taskName: templateForm.taskName
        }
      ]
      this.form.taskName = templateForm.taskName
      this.form.routeName = templateForm.routeName
    },

    handleCloseTaskModal() {
      this.isExit = true
      this.clearTimeoutEx()
      this.$emit('closeTaskModal')
    },
    handleCancelTask() {
      this.$emit('closeTaskModal')
      this.ModalVisible = false
    },
    /** 提交按钮 */
    submitForm() {
      this.form.vehicleId = this.vehicleId
      const form = this.form
      if (!form.open && !form.vehicleTaskId && !form.mapId) {
        this.ModalVisible = false
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.open) {
            // 返回固定点任务
            const formData = new FormData()
            formData.append('vehicleId', this.vehicleId)
            formData.append('raTaskId', this.form.vehicleTaskId)
            this.$loading.show({
              msg: '正在设置返回固定点任务'
            })
            autoReset(formData)
              .then((res) => {
                console.log('autoReset', res)
                // 获取动态任务操作结果
                this.taskIsRunning = true
                this.isClearTimeout = false
                this.taskAckBeginTickount = new Date()
                const params = {
                  vehicleId: this.vehicleId
                }
                this.getTaskAck(autoResetAck, params, 'autoReset')
              })
              .catch((e) => {
                this.$loading.hide()
              })
          } else {
            const formData = new FormData()
            formData.append('vehicleId', this.vehicleId)
            formData.append('mapId', this.form.mapId)
            formData.append('mapVersion', this.form.mapVersion)
            this.$loading.show({
              msg: '正在取消返回固定点任务'
            })
            cancelAutoReset(formData)
              .then((res) => {
                console.log('autoReset', res)
                // 获取动态任务操作结果
                this.taskIsRunning = true
                this.isClearTimeout = false
                this.taskAckBeginTickount = new Date()
                const params = {
                  vehicleId: this.vehicleId
                }
                this.getTaskAck(autoResetAck, params, 'cancelAutoReset')
              })
              .catch((e) => {
                this.$loading.hide()
              })
          }
        } else {
          return false
        }
      })
    },
    toEnd(infoType, result) {
      this.$loading.hide()
      let myModal = this.$successEx
      if (infoType === 'warn') {
        myModal = this.$warningEx
      } else if (infoType === 'error') {
        myModal = this.$errorEx
      }
      myModal(result, () => {
        this.clearTimeoutEx()
        this.$emit('closeTaskModal')
        this.$emit('update')
        this.ModalVisible = false
      })
    },
    clearTimeoutEx() {
      this.createTaskTimeoutId && clearTimeout(this.createTaskTimeoutId)
    },
    handleTaskSearch2() {
      if (this.taskArray.length <= 1) {
        this.handleTaskSearch(' ')
      }
    },
    handleTaskSearch(value) {
      console.log('handleTaskSearch', value)
      const queryParam = {
        taskName: value,
        vehicleId: this.vehicleId,
        status: 0,
        vehicleCreate: 0,
        mapId: this.currentMapId,
        mapVersion: this.currentMapVersion
      }
      if (value && value.trim() === '') {
        delete queryParam.taskName
        queryParam.pageNum = 1
        queryParam.pageSize = 10
      }
      this.loading = true
      listVehicleTask(queryParam).then((response) => {
        console.log('listVehicleTask')
        this.taskArray = response.rows
        this.loading = false
        this.$forceUpdate()
      })
    },
    handleTaskChange(value) {
      const record = this.taskArray.find((p) => p.id === value)
      if (record) {
        this.form.taskName = record.taskName
        this.form.taskType = record.taskType
        this.form.mapId = record.mapId
        this.form.mapVersion = record.mapVersion
        this.form.routeFolderName = record.routeFolderName
        this.form.routeName = record.routeName
        this.form.lon = record.lon
        this.form.lat = record.lat
      }
      this.clearFormFields()
    }
  }
}
</script>
<style lang="less">
.send-task {
  .ant-form-item {
    margin-bottom: 11.2px !important;
  }
}
.task-conflict {
  height: 25px;
}
.spin-content {
  padding: 20px;
  padding-left: 70px;
  font-weight: 600;
}
.send-task {
  .ant-spin-blur {
    opacity: 1 !important;
  }
  .ant-spin {
    left: 0 !important;
    top: 30px !important;
    height: 0 !important;
  }
  .ant-spin-dot {
    left: 50px !important;
  }
}
</style>
